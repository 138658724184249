import { defaultFeedbackToastComponentProps } from "../cubeComponent/constants/globalVariable";

const initialBcfState = {
  currentBcfId: "",
  bcfData: null,
  isAutoBcfLoaded: false,
};

export function itemsHaveError(state = false, action) {
  switch (action.type) {
    case "ITEMS_HAVE_ERROR":
      return action.hasError;

    default:
      return state;
  }
}

export function toastNotificationContent(state = {}, action) {
  switch (action.type) {
    case "SET_TOAST_DATA":
      if (action.data.content) {
        return { ...defaultFeedbackToastComponentProps, ...action.data };
      }

      return {};

    default:
      return state;
  }
}

export function itemsAreLoading(state = false, action) {
  switch (action.type) {
    case "ITEMS_ARE_LOADING":
      return action.isLoading;

    default:
      return state;
  }
}

export function items(state = [], action) {
  switch (action.type) {
    case "ITEMS_FETCH_DATA_SUCCESS":
      return action.items;

    default:
      return state;
  }
}

export function bcfData(state = initialBcfState, action) {
  switch (action.type) {
    case "SAVE_BCF_DATA":
      if (action.id) {
        return { ...state, currentBcfId: action.id };
      }
      break;
    case "SET_BCF_BUFFER_DATA":
      if (action.data) {
        return { ...state, bcfData: action.data };
      }
      break;
    case "SET_AUTO_BCF_LOADED":
      if (action.param) {
        return { ...state, isAutoBcfLoaded: action.param };
      }
      break;
    default:
      return state;
  }
}
