export function userTagData(state = {}, action) {
  switch (action.type) {
    case "SET_USERTAG_LOADING":
      return {
        ...state,

        [action.id]: {
          isLoading: true,
          isLoaded: false,
          data: null,
        },
      };

    case "SET_USERTAG_DATA":
      return {
        ...state,

        [action.id]: {
          isLoading: false,
          isLoaded: true,
          data: action.data,
        },
      };

    default:
      return state;
  }
}
