export function projectData(state = {}, action) {
  switch (action.type) {
    case "SET_PROJECT_DATA":
      return action.data;
    default:
      return state;
  }
}

export function allSpacesInProject(state = [], action) {
  switch (action.type) {
    case "SET_ALL_SPACES_IN_PROJECT":
      return action.data;
    default:
      return state;
  }
}

export function spaceData(state = [], action) {
  switch (action.type) {
    case "GET_SPACE_DATA":
      return action.data;
    default:
      return state;
  }
}

export function breadCrumbData(state = [], action) {
  switch (action.type) {
    case "SET_BREADCRUMB_DATA":
      return action.data;
    default:
      return state;
  }
}
