import axiosWrapper from "../services/axiosWrapper";

export function saveUserPermissions(data) {
  return {
    type: "SET_USER_PERMISSIONS",
    data,
  };
}

export function savePortalSetting(data) {
  return {
    type: "SET_PORTAL_SETTING_INFO",
    data,
  };
}

export function getUserPermissions(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/permissions/getUserPermissions`,
        data,
      )
      .then((response) => {
        if (response && response.data.status) {
          dispatch(saveUserPermissions(response.data.data));
        }
      });
  };
}
export const getNotificationPreference = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/userSettings/getNotificationPreference`,
    data,
  );
  return response;
};

export function getPortalSetting() {
  return async (dispatch) => {
    const responseData = await axiosWrapper.get(
      `${process.env.REACT_APP_CUBE_BACKEND}/portal/getPortalSettingsObject`,
    );
    if (responseData.status) {
      dispatch(savePortalSetting(responseData.data.data));
    }
  };
}
export const saveUserSettings = async (data) => {
  const response = axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/userSettings/saveUserSettings`,
    data,
  );
  return response;
};
export const getUserSettings = async (data) => {
  const response = axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/userSettings/getUserSettings`,
    data,
  );
  return response;
};
export const deleteUserSettings = async (data) => {
  const response = axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/userSettings/deleteUserSettings`,
    data,
  );
  return response;
};
