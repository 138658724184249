const initialCommonState = {
  isModalState: false,
};

export function commonObjectData(state = initialCommonState, action) {
  switch (action.type) {
    case "SET_MODAL_STATE":
      return { ...initialCommonState, isModalState: action.data };
    default:
      return state;
  }
}

export function fileversionIdAndNodeOffsetIdMap(state = {}, action) {
  switch (action.type) {
    case "SET_FILEVERSIONID_AND_NODE_OFFSETID_MAPPER":
      return action.data;
    default:
      return state;
  }
}
