import { Link, useNavigate } from "react-router-dom";
import css from "./index.module.css";
import { useState } from "react";
import IconComponent from "../../cubeComponent/icons";
import { getUrlForImage } from "../../cubeComponent/constants/globalFunctions";
import { useSelector } from "react-redux";

function NotificationCard(props) {
  const layout = useSelector((state) => state.layout);

  const unreadNotificationsCount = useSelector(
    (state) => state.unreadNotificationsCount,
  );
  const navigate = useNavigate();

  const [hoveredNotification, setHoveredNotification] = useState(null);

  const handleMouseEnter = (notification) => {
    setHoveredNotification(notification.id);
  };

  const handleMouseLeave = () => {
    setHoveredNotification(null);
  };

  const handleRedirect = (redirectUrl) => {
    navigate(redirectUrl);
    props.closeNotificationModal();
  };

  const notificationClicked = (redirectUrl, notificationId) => {
    if (redirectUrl) {
      props.dismissNotification(notificationId);
      handleRedirect(redirectUrl);
    }
  };

  function ImageWithFallback({ imageUrl, defaultImage }) {
    const [imgSrc, setImgSrc] = useState(imageUrl);

    const handleError = () => {
      setImgSrc(defaultImage);
    };

    return (
      <img
        className="w-[24px] rounded-full mx-[5px] h-[24px] min-w-[48px] min-h-[48px]"
        src={imgSrc}
        onError={handleError}
        alt="Project Image"
      />
    );
  }

  return (
    <div className="w-full">
      {props.title === "New" && (
        <div className="flex items-center h-[64px] w-full">
          <div className="pl-8 py-4 font-semibold">Notifications</div>
          <div className="inline-flex items-center p-1 ml-2 text-sm font-bold bg-[#ECF3FE] rounded">
            {unreadNotificationsCount.count}
          </div>
        </div>
      )}

      <div className="flex flex-col">
        <div
          className="bg-[#ECF3FE] text-black font-semibold flex px-8 text-[16px] p-2 "
          hidden={!props.notificationsList.length}
        >
          {props.title}
        </div>

        <div className={css.msgContainer}>
          {props.notificationsList.map((notification, index) => (
            <div
              className={`${
                index === 4 && props.title === "New"
                  ? ""
                  : "border-b border-[#ECF3FE]"
              } px-[32px] py-[16px]`}
            >
              <div className="flex items-center justify-between">
                <div
                  onClick={() =>
                    notificationClicked(
                      notification.redirectUrl,
                      notification.id,
                    )
                  }
                  onMouseEnter={() => handleMouseEnter(notification)}
                  onMouseLeave={handleMouseLeave}
                  className={`flex ${
                    hoveredNotification === notification.id &&
                    notification.redirectUrl
                      ? "cursor-pointer"
                      : hoveredNotification === notification.id &&
                          !notification.redirectUrl
                        ? "cursor-not-allowed"
                        : ""
                  }`}
                  key={notification.id}
                >
                  <div className="flex items-center">
                    <div className="rounded-full bg-blue-100 w-[48px] h-[48px] flex items-center justify-center">
                      {notification.type === "Project" ? (
                        <ImageWithFallback
                          imageUrl={getUrlForImage(
                            { id: `${notification.projectId}` },
                            "projectImage",
                          )}
                          defaultImage={`${
                            process.env.REACT_APP_IMAGES_PUBLIC_URL +
                            layout.PROJECT_LOGO
                          }`}
                        />
                      ) : (
                        <IconComponent
                          className="w-[24px] mx-[5px] h-[24px]"
                          name={
                            notification.type === "Transmittal"
                              ? "Transmittals"
                              : notification.type === "Setting"
                                ? "SettingsIcon"
                                : notification.type === "Space"
                                  ? "SpacesIcon"
                                  : notification.type === "Issue"
                                    ? "IssuesIcon"
                                    : notification.type === "File"
                                      ? "FilesIcon"
                                      : notification.type === "Task"
                                        ? "TasksIcon"
                                        : notification.type === "Estimate"
                                          ? "EstimateIcon"
                                          : notification.type === "Review"
                                            ? "Review"
                                            : notification.type ===
                                                "GeneralForms"
                                              ? "generalForms"
                                              : "DefaultIcon"
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div className={css.userMessage}>
                    <p className={css.message}>{notification.text}</p>
                  </div>
                </div>

                <div className="flex items-center justify-between w-[121px]">
                  <p className="text-gray-500 text-xs">{notification.time}</p>

                  {props.title === "New" && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        props.dismissNotification(notification.id);
                      }}
                      className="pl-[24px] cursor-pointer"
                      title="Mark as read"
                    >
                      &#10005;
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NotificationCard;
