import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { useDispatch, useSelector } from "react-redux";
import css from "./index.module.css";
import { RxCrossCircled } from "react-icons/rx";
import {
  emptyMinimizerData,
  removeMinimizerData,
  setMinimizerData,
} from "../../actions/minimizeFloatter";
import { useNavigate } from "react-router-dom";
import cubeIcon from "../../assets/icons/svg/cube_img.svg";
import { useEffect, useState, useRef } from "react";
import fileIcon from "../../assets/icons/svg/FileDWG.svg";
import closeIcon from "../../assets/icons/svg/close.svg";

export function MinimizeFloatter({}) {
  const [dropDownState, setDropDownState] = useState(false);
  const minimizeFloatterData = useSelector(
    (state) => state.minimizeFloatterData,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();

  const handler = (event) => {
    if (!ref.current?.contains(event.target)) {
      setDropDownState(false);
    }
  };

  useEffect(() => {
    handler();
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const handleRemoveIcon = () => {
    dispatch(emptyMinimizerData());
  };

  const handleSelectItem = (event) => {
    const index = event.currentTarget.attributes["data-set"].value;
    navigate(minimizeFloatterData[index].path);
    dispatch(removeMinimizerData(minimizeFloatterData[index].index));
  };

  const removeSelectItem = (event) => {
    const index = event.currentTarget.attributes["data-set"].value;
    dispatch(removeMinimizerData(minimizeFloatterData[index].index));
  };

  const changeState = () => {
    setDropDownState((prevState) => !prevState);
  };

  return (
    <>
      {minimizeFloatterData.length ? (
        <div ref={ref} className={css.root}>
          {dropDownState ? (
            <div className={css.mainContainer}>
              <div className={css.header}>
                <span className={css.openFile}>Open Files</span>
                <span className={css.closeFile} onClick={handleRemoveIcon}>
                  <strong>Close All</strong>
                </span>
              </div>
              {minimizeFloatterData.map((props, index) => (
                <div className={`${css.flexRowStart} ${css.fileDetails}`}>
                  <div className={css.fileSideIcon}>
                    <img src={fileIcon} />
                  </div>
                  <div
                    className={css.fileDiv}
                    onClick={handleSelectItem}
                    data-set={index}
                  >
                    <p className={css.fileName}>{props.text}</p>
                  </div>
                  <div className={css.closeIconDiv}>
                    <img
                      src={closeIcon}
                      data-set={index}
                      onClick={removeSelectItem}
                      className={css.closeIcon}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          <img src={cubeIcon} className={css.bgIcon} onClick={changeState} />
        </div>
      ) : null}
    </>
  );
}

export default MinimizeFloatter;
