import React, { FC, forwardRef, useRef } from "react";
import { DropDownListComponent as SyncfusionDropDownListComponent, DropDownListModel } from '@syncfusion/ej2-react-dropdowns';
import { createUseStyles } from "react-jss";
import { DropdownListComponentProps } from "../../interface";
import { getDropdownListCssClass } from "../../constants/globalFunctions";

const DropDownListComponent: FC<DropdownListComponentProps & DropDownListModel> = forwardRef((props: DropdownListComponentProps & DropDownListModel, ref) => {
  const dropdownRef = ref || useRef()
  const iconDropdownWhite = () => {

    return {
      "& .e-input-group-icon": {
        marginLeft: "var(--size-10) !important",
        color: `var(--surface-white)  !important`,
        fontWeight: "600 !important"
      },
    }
  }
  const commonCss = () => {
    return {
      '&.e-keyboard': {
        height: '32px',
      },
      "& .e-list-parent .e-list-item.e-hover": {
        background: `var(--surface-stroke-gradient-color1) !important`,
        color: `var(--primary-text) !important`,
      },
      "& .e-list-parent .e-list-item:focus,& .e-list-parent .e-list-item.e-active": {
        background: `var(--surface-stroke-gradient-color1) !important`,
        color: `var(--primary-text) !important`,
        fontWeight: 600
      },
      "& .e-input": {
        fontWeight: props.fontWeightInput ? `${props.fontWeightInput} !important` : "600 !important"
      },
      "&:disabled": {
        background: `var(--surface-stroke-gradient-color1) !important`,
        color: `var(--disabled-text-gray) !important`,
      },
    }
  }
  const useStyles = createUseStyles({
    dropdownGradientList: {},
    dropdownBackgroundList: {
      background: props.bgColor ? `${props.bgColor} !important` : `var(--surface-stroke-gradient-color1) !important`,
      color: `var(--primary-text)  !important`,
      borderRadius: "var(--size-2) !important",
      border: props.border ? `${props.border} !important` : 'none !important',
      '& .e-dropdownbase': {
        background: 'white',
        '& .e-list-item': {
          lineHeight: '36px !important'
        }
      },
      "& .e-input-group-icon": {
        marginLeft: "var(--size-10) !important",
        color: `var(--primary-text)  !important`,
        fontWeight: "600 !important"
      },
      ...commonCss(),
    },
    dropdownBackgroundYellowList: {
      background: `var(--system-colour-9) !important`,
      color: `var(--surface-white)  !important`,
      borderRadius: "var(--size-2) !important",
      border: "none !important",
      ...iconDropdownWhite(),
      ...commonCss(),
    },

    dropdownBackgroundGreenList: {
      background: `var(--system-colour-7) !important`,
      color: `var(--surface-white)  !important`,
      borderRadius: "var(--size-2) !important",
      border: "none !important",
      ...iconDropdownWhite(),
      ...commonCss(),
    },
    dropdownBackgroundRedList: {
      background: `var(--system-colour-3) !important`,
      color: `var(--surface-white)  !important`,
      borderRadius: "var(--size-2) !important",
      border: "none !important",
      ...iconDropdownWhite(),
      ...commonCss(),
    },
    dropdownBackgroundRoyalBlueList: {
      background: `var(--system-colour-45) !important`,
      color: `var(--surface-white)  !important`,
      borderRadius: "var(--size-2) !important",
      border: "none !important",
      ...iconDropdownWhite(),
      ...commonCss(),
    },
    dropdownBackgroundTextLightList: {
      background: `var(--text-light) !important`,
      color: `var(--surface-white)  !important`,
      borderRadius: "var(--size-2) !important",
      border: "none !important",
      ...iconDropdownWhite(),
      ...commonCss(),
    }
  });

  const classes = useStyles() as any;

  /******************* 
@Purpose : Dropdown List Component
@Parameter : DropdownListComponentProps
@Author : Techture
 *******************/

  const actionComplete = () => {
    // patch to change value through ref programatically
    if (dropdownRef.current && props.value !== undefined) {
      dropdownRef.current.value = props.value
    }
    if (props.actionComplete) {
      props.actionComplete(arguments)
    }
  }

  const parentStyles: any = { width: props.width, display: 'flex', flexDirection: 'column', gap: '12px' }

  if (props.allowFlexGrow) {
    parentStyles.flexGrow = 1
  }

  return (
    <>
      <div style={parentStyles}>
        <SyncfusionDropDownListComponent
          ref={dropdownRef}
          {...props}
          // actionComplete={actionComplete}
          cssClass={`${getDropdownListCssClass(props.buttonType as string, classes as any)} ${props.cssClass}`}
        />
      </div>
    </>
  );
}
)
DropDownListComponent.defaultProps = {};
export default DropDownListComponent;

