import React, { FC } from "react";
import { SliderComponent as SyncfusionSliderComponent, SliderModel } from "@syncfusion/ej2-react-inputs";
import { createUseStyles } from "react-jss";
import { SliderComponentProps } from "../../interface";
import { defaultSliderComponentProps } from "../../constants/globalVariable";
import {
  getSliderParentWrapperStyle,
  getSliderStyle,
} from "../../constants/globalFunctions";

const SliderComponent: FC<SliderComponentProps & SliderModel> = (props: SliderComponentProps & SliderModel) => {
  const useStyles = createUseStyles({
    parentWrapper: getSliderParentWrapperStyle(),
    slider: getSliderStyle(
      props.gradientColor1 as string,
      props.gradientColor2 as string,
      props.color as string,
      props.handleShadowColor as string,
      props.handleGradientColor1 as string,
      props.handleGradientColor2 as string
    ),
  });

  const classes = useStyles() as any;

  /******************* 
@Purpose : Slider Component
@Parameter : SliderComponentProps
@Author : Techture
 *******************/

  return (
    <div className={classes.parentWrapper}>
      <SyncfusionSliderComponent
        {...props}
        type="MinRange"
        cssClass={classes.slider}
      ></SyncfusionSliderComponent>
    </div>
  );
};
SliderComponent.defaultProps = defaultSliderComponentProps;
export default SliderComponent;
