import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonComponent from "../../../cubeComponent/form/ButtonComponent";
import { BUTTON_TYPES } from "../../../cubeComponent/constants/globalVariable";
import css from "./index.module.css";
import axiosWrapper from "../../../services/axiosWrapper";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { showToastNotification } from "../../../actions/toast";
import {
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_ERROR,
} from "../../../services/constants";
import _ from "lodash";

function FileUpload({
  selectedFiles,
  onCloseModal,
  path,
  onDeleteSuccess,
  workspaceId,
  isFileUpload = false,
  onDeleteFiles,
}) {
  const dispatch = useDispatch();

  let selectedResource = "file";
  const resourceType = _.get(selectedFiles, "[0].resourceType");
  if (resourceType == "RESOURCE_TYPE_SCHEDULE") {
    selectedResource = "schedule";
  } else if (resourceType == "RESOURCE_TYPE_ESTIMATE") {
    selectedResource = "estimate";
  }

  const checkIfFoldersAndFiles = { folder: false, file: false };

  if (selectedFiles.length === 1) {
    if (resourceType == "RESOURCE_TYPE_FOLDER") {
      selectedResource = "folder";
    }
  } else {
    selectedFiles.forEach(({ resourceType }) => {
      if (resourceType == "RESOURCE_TYPE_FOLDER") {
        checkIfFoldersAndFiles.folder = true;
      } else {
        checkIfFoldersAndFiles.file = true;
      }
    });

    if (
      checkIfFoldersAndFiles.file == true &&
      checkIfFoldersAndFiles.folder == true
    ) {
      selectedResource = "items";
    } else if (
      checkIfFoldersAndFiles.file == false &&
      checkIfFoldersAndFiles.folder == true
    ) {
      selectedResource = "folders";
    } else {
      selectedResource = "files";
    }
  }

  const [constituentFilesMap, setConstituentFilesMap] = useState({});
  const [showConstituentWarning, setShowConstituentWarning] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [deleteAssociatedTasks, setDeleteAssociatedTasks] = useState(true);
  const [deleteWarningText, setDeleteWarningText] = useState(
    `Are you sure you want to delete ${
      selectedFiles.length === 1 ? "this" : "these"
    } ${
      selectedFiles.length > 1 ? selectedFiles.length : ""
    } ${selectedResource}?`,
  );
  const isSchedule = resourceType == "RESOURCE_TYPE_SCHEDULE";
  const isEstimate = resourceType == "RESOURCE_TYPE_ESTIMATE";
  useEffect(() => {
    if (Object.values(constituentFilesMap).some((value) => value.length > 0)) {
      setShowConstituentWarning(true);
      setShowDeleteWarning(false);
    } else {
      setShowDeleteWarning(true);
      if (isSchedule) {
        setDeleteWarningText(
          "All constituent schedules will also get deleted. Are you sure you want to delete?",
        );
      }
    }
  }, [constituentFilesMap]);

  useEffect(() => {
    if (!isFileUpload) {
      checkIfFileIsConstituentFile();
    }
  }, []);

  const checkIfFileIsConstituentFile = async () => {
    if (isEstimate || isSchedule) {
      return;
    }
    const fileId = selectedFiles.map((file) => file.hierarchyId);
    const data = {
      fileId,
    };
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/fileUpload/getParentOfConstituentFiles`,
      data,
    );
    setConstituentFilesMap(response.data.data || {});
  };

  const handleDeleteFileButton = async () => {
    if (onDeleteFiles) {
      onDeleteFiles();
    }
    const selectedFilesToDelete = [];
    selectedFiles.map((eachObj) => {
      const data = {
        ...eachObj,
        spaceId: workspaceId,
        deleteAssociatedTasks,
      };
      if (!isFileUpload || eachObj.hierarchyId) {
        selectedFilesToDelete.push(data);
      }
    });
    const data = {
      action: "delete",
      data: selectedFilesToDelete,
      path,
    };
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer`,
      data,
    );
    if (response.data.status) {
      onDeleteSuccess(true);
    } else {
      onDeleteSuccess(false);
      dispatch(
        showToastNotification({
          content: response.data.message,
          type: TOAST_TYPE_ERROR,
        }),
      );
    }
  };

  const handleCancelDeleteFileButton = () => {
    onCloseModal();
  };

  return (
    <div
      style={{
        backgroundColor: "var(--background-scrim)",
        position: "absolute",
        margin: "auto",
        zIndex: "20",
        width: "100%",
        height: "100%",
        top: "0",
      }}
    >
      <div
        style={{
          top: "40%",
          left: "30%",
          backgroundColor: "white",
          position: "absolute",
          padding: "16px",
          width: "45%",
        }}
      >
        <div
          style={{ display: "grid", fontSize: "20px" }}
          className="dialogContent"
        >
          <div>{`Delete ${selectedResource}`}</div>
        </div>
        <div
          style={{ display: "grid", paddingTop: "16px", gridColumnGap: "16px" }}
        >
          {showDeleteWarning && (
            <div style={{ fontSize: "14px" }}>{deleteWarningText}</div>
          )}
          {showConstituentWarning && (
            <div style={{ fontSize: "14px" }}>
              <div style={{ paddingBottom: "16px" }}>
                Below files are the constituent files of
              </div>
              <div style={{ paddingBottom: "16px" }}>
                {Object.keys(constituentFilesMap).map((fileId, index) => {
                  if (constituentFilesMap[fileId].length > 0) {
                    let fileName = selectedFiles.filter(
                      (file) => file.hierarchyId == fileId,
                    );
                    const parentFileNames = constituentFilesMap[fileId]
                      .map((file) => file.name)
                      .join(", ");
                    fileName = fileName[0].name;

                    return (
                      <div style={{ display: "flex" }}>
                        <div>{fileName}</div>
                        <div
                          style={{ paddingLeft: "16px", fontWeight: "bold" }}
                        >
                          {parentFileNames}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              <div>Are you sure you want to delete?</div>
            </div>
          )}
          {isSchedule && (
            <span>
              {" "}
              <CheckBoxComponent
                label="Delete associated tasks"
                id="deleteTask"
                checked={deleteAssociatedTasks}
                onChange={(args) => {
                  setDeleteAssociatedTasks(args.target.checked);
                }}
              />{" "}
            </span>
          )}
          <div style={{ paddingTop: "16px", display: "flex", gap: "24px" }}>
            <ButtonComponent
              onClick={handleCancelDeleteFileButton}
              cssClass={`e-flat ${css.buttonLight}`}
              buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
            >
              Cancel
            </ButtonComponent>

            <ButtonComponent
              onClick={handleDeleteFileButton}
              cssClass="e-info"
              buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
            >
              Confirm
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileUpload;
