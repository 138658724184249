export function currencyDetails(state = [], action) {
  switch (action.type) {
    case "SET_CURRENCY_DETAILS":
      return {
        ...action.data,
      };

    default:
      return state;
  }
}
