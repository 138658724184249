export function userPermissions(state = {}, action) {
  switch (action.type) {
    case "SET_USER_PERMISSIONS":
      return action.data;
    default:
      return state;
  }
}

export function portalSettingInfo(state = {}, action) {
  switch (action.type) {
    case "SET_PORTAL_SETTING_INFO":
      return action.data;
    default:
      return state;
  }
}
