const initialProjectDashboardDataState = {
  isModalVisible: false,
  title: "",
  component: null,
};
export function projectDashboardData(
  state = initialProjectDashboardDataState,
  action,
) {
  switch (action.type) {
    case "SET_PROJECT_MODAL_VISIBLITY":
      return { ...state, ...action.data };
    default:
      return state;
  }
}
