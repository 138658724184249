import { store } from "./App";
import { Provider } from "react-redux";
import { useState, createContext } from "react";
import { THEME_LIGHT, THEME_DARK } from "./App.constant";
import { PubSubContext } from "./App";

export const themes = {
  [THEME_LIGHT]: {
    rootBackground: "--surface-neutral",
    rootTextColor: "--primary-text",
    userNameTagComponentBackground: "--light-blue-tag",
  },
  [THEME_DARK]: {
    rootBackground: "--surface-black",
    rootTextColor: "--surface-neutral",
  },
};

export const ThemeContext = createContext({
  theme: themes[THEME_LIGHT],
});

function ProviderApp({ children }) {
  const [theme, setTheme] = useState(THEME_LIGHT);

  return (
    <PubSubContext.Provider>
      <ThemeContext.Provider
        value={{
          theme: themes[theme],
          setTheme,
          themes,
          themeType: theme,
        }}
      >
        <Provider store={store}>{children}</Provider>
      </ThemeContext.Provider>
    </PubSubContext.Provider>
  );
}

export default ProviderApp;
