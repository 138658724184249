const initialPortalDashboardDataState = {
  isModalVisible: false,
  title: "",
  component: null,
};
export function portalDashboardData(
  state = initialPortalDashboardDataState,
  action,
) {
  switch (action.type) {
    case "GET_PORTAL_DATA":
      return action.data;
    case "SET_MODAL_VISIBLITY":
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export function portalDashboardObject(state = {}, action) {
  switch (action.type) {
    case "GET_PORTAL_DASHBOARD_OBJECT":
      return action.data;
    default:
      return state;
  }
}
