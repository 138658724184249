export const selectedSet = (state = "-1", { type, data }) => {
  switch (type) {
    case "SET_SELECTED_SET":
      return data;
    default:
      return state;
  }
};

export const selectionList = (state = [], { type, data }) => {
  switch (type) {
    case "SET_SELECTION_LIST":
      return data;
    default:
      return state;
  }
};

export const selectionListIdObj = (state = {}, { type, data }) => {
  switch (type) {
    case "SET_SELECTION_LISTID_OBJ":
      return data;
    default:
      return state;
  }
};

export const selectedSelectionSetItemsList = (state = [], { type, data }) => {
  switch (type) {
    case "SET_SELECTED_SELECTIONSET_ITEMSLIST":
      return data;
    default:
      return state;
  }
};
