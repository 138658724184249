import axiosWrapper from "../services/axiosWrapper";

export function setLayout(data) {
  return {
    type: "SET_LAYOUT",
    data,
  };
}

export function fetchLayout() {
  return async (dispatch) => {
    const response = await axiosWrapper.get(
      `${process.env.REACT_APP_CUBE_BACKEND}/portal/getLayoutData`,
    );
    if (response.data.status) {
      dispatch(setLayout(response.data.data));
    }
  };
}
