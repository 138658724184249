const initialLicenceState = true;
const initialPrivacyPolicyStatus = {
  status: true,
};

export function licenceStatus(state = initialLicenceState, action) {
  switch (action.type) {
    case "SET_LICENCE_VALIDITY":
      return action.data;
    default:
      return state;
  }
}

export function privacyPolicyStatus(
  state = initialPrivacyPolicyStatus,
  action,
) {
  switch (action.type) {
    case "SET_PRIVACY_POLICY_STATUS":
      return action.data;
    default:
      return state;
  }
}
