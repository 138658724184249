function ShimmerProfile() {
  return (
    <div className="animate-pulse flex flex-col items-center">
      <div className="bg-gray-300 rounded-full h-24 w-24 mb-4" />
      <div className="h-4 bg-gray-300 rounded w-32 mb-2" />
      <div className="h-3 bg-gray-300 rounded w-24 mb-2" />
      <div className="h-3 bg-gray-300 rounded w-20 mb-2" />
      <div className="h-3 bg-gray-300 rounded w-36" />
    </div>
  );
}

export default ShimmerProfile;
