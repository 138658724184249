export function roleData(state = [], action) {
  switch (action.type) {
    case "SET_PROJECT_ROLE_LIST":
      return action.data;
    default:
      return state;
  }
}

export function spacesUsersData(state = [], action) {
  switch (action.type) {
    case "SET_SPACES_LIST":
      return action.data;
    default:
      return state;
  }
}

export function setEntityList(state = [], action) {
  switch (action.type) {
    case "SET_ENTITY_LIST":
      return action.data;
    default:
      return state;
  }
}

export function statusData(state = [], action) {
  switch (action.type) {
    case "SET_STATUS_LIST":
      return action.data;
    default:
      return state;
  }
}
export function setCategoryList(state = [], action) {
  switch (action.type) {
    case "SET_CATEGORY_LIST":
      return action.data;
    default:
      return state;
  }
}
export function deleteSpace(state = [], action) {
  switch (action.type) {
    case "DELETED_SPACE":
      return action.data;
    default:
      return state;
  }
}

export function DeletedUserState(state = [], action) {
  switch (action.type) {
    case "DELETED_USER":
      return action.data;
    default:
      return state;
  }
}

export function getProjectSettingObject(state = {}, action) {
  switch (action.type) {
    case "PROJECT_SETTING_OBJECT":
      return action.data;
    default:
      return state;
  }
}
