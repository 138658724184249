import { getUrlForImage } from "../cubeComponent/constants/globalFunctions";
import axiosWrapper from "../services/axiosWrapper";
import { Query } from "@syncfusion/ej2-data";
import {
  getDataManagerObj,
  includedProjectsUrl,
} from "../services/dataManager";

export function itemsHaveError(bool) {
  return {
    type: "ITEMS_HAVE_ERROR",
    hasError: bool,
  };
}
export function setProjectData(data) {
  return {
    type: "SET_PROJECT_DATA",
    data,
  };
}

export function setAllSpacesInProjects(data) {
  return {
    type: "SET_ALL_SPACES_IN_PROJECT",
    data,
  };
}

export function getspacedata(data) {
  return {
    type: "GET_SPACE_DATA",
    data,
  };
}
export function itemsAreLoading(bool) {
  return {
    type: "ITEMS_ARE_LOADING",
    isLoading: bool,
  };
}

export function setBreadCrumbData(data) {
  return {
    type: "SET_BREADCRUMB_DATA",
    data,
  };
}

export function getCurrProjectData(id) {
  return async (dispatch) => {
    const query = new Query().where("_id", "equal", id);
    let response =
      await getDataManagerObj(includedProjectsUrl).executeQuery(query);
    response = response.result[0];

    if (response?.image != "") {
      dispatch(
        setProjectData({
          ...response,
          projectImageUrl: getUrlForImage({ id }, "projectImage"),
        }),
      );
    } else {
      dispatch(setProjectData({ ...response }));
    }
  };
}

export function getAllSpacesInCurrProject(id) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .get(
        `${process.env.REACT_APP_CUBE_BACKEND}/projects/getAllSpacesDataByProjectId/${id}`,
      )
      .then((res) => {
        const projectData = res.data;
        return projectData;
      })
      .then((res) => dispatch(setAllSpacesInProjects(res.data)))
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function getCurrSpaceData(id) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));
    axiosWrapper
      .get(`${process.env.REACT_APP_CUBE_BACKEND}/spaces/getSpaceById/${id}`)
      .then((res) => {
        const spaceData = res.data;
        return spaceData;
      })
      .then((res) => dispatch(getspacedata(res.data)))
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function getSpaceDataBySpaceId(id) {
  return async (dispatch) => {
    axiosWrapper
      .get(`${process.env.REACT_APP_CUBE_BACKEND}/spaces/getSpaceById/${id}`)
      .then((res) => {
        dispatch(getspacedata(res.data));
      })
      .catch((err) => console.log("ERROR IN GETSPACEDATABYSPACEID ", err));
  };
}
