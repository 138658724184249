import axiosWrapper from "../services/axiosWrapper";

export function setLicenceValidity(data) {
  return {
    type: "SET_LICENCE_VALIDITY",
    data,
  };
}

export function setPrivacyPolicyStatus(data) {
  return {
    type: "SET_PRIVACY_POLICY_STATUS",
    data,
  };
}

export function checkIfPrivacyPolicyAccepted() {
  return (dispatch) => {
    axiosWrapper
      .get(
        `${process.env.REACT_APP_CUBE_BACKEND}/globalSettings/isUserAcceptedPrivayPolicy`,
      )
      .then((response) => {
        if (!response.data.status) {
          dispatch(setPrivacyPolicyStatus(response.data));
        }
      })
      .catch(() => {});
  };
}

export function updatePrivacyPolicyStatus() {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/globalSettings/updatePrivacyPolicyStatus`,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(setPrivacyPolicyStatus(response.data));
        }
      })
      .catch(() => {});
  };
}
