export function spaceUserDataForFolder(state = [], action) {
  switch (action.type) {
    case "SET_SPACE_USER_LIST_FOR_FOLDER":
      return action.data;
    default:
      return state;
  }
}

export function setFolderRoles(state = [], action) {
  switch (action.type) {
    case "SET_FOLDER_ROLES":
      return action.data;
    default:
      return state;
  }
}

export function setFolderData(state = [], action) {
  switch (action.type) {
    case "SET_FOLDER_DATA":
      return action.data;
    default:
      return state;
  }
}

export function getAccessPermission(state = {}, action) {
  switch (action.type) {
    case "GET_ACCESS_PERMISSION":
      return action.data;
    default:
      return state;
  }
}

export function getSpaceToggle(state = [], action) {
  switch (action.type) {
    case "GET_SPACE_TOGGLE":
      return action.data;
    default:
      return state;
  }
}
