import { useNavigate, useLocation } from "react-router-dom";
import css from "./index.module.css";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import ModalStateDialogComponent from "@CubeComponents/html/modal/ModalWithState";

function Welcome() {
  const portalData = useSelector((state) => state.portalData);
  const location = useLocation();
  const [showTutorial, setShowTutorial] = useState(false);
  const [activeSection, setActiveSection] = useState("createProject");
  const commonClass =
    "pt-8 pr-10 pb-8 pl-4 border-button_light border-solid border cursor-pointer";
  const activeClass =
    "border-solid border-l-4 border-y-2 border-r-0 border-gradient_color";
  const navigate = useNavigate();

  console.log(location);

  useEffect(() => {
    if (location.hash == "#tutorial") {
      setShowTutorial(true);
    }
  }, []);

  const handleDashboardRedirect = () => {
    navigate("/dashboard");
  };

  const handleDocumentationRedirect = () => {
    window.open("https://support.cube.ms/space/CKB", "_blank");
  };

  const handleShowTutorial = () => {
    setShowTutorial(true);
  };

  const handleHideTutorial = () => {
    setShowTutorial(false);
  };

  const handleSectionClick = (event) => {
    setActiveSection(event.target.id);
  };

  const createProjectDiv = () => (
    <div
      style={{
        position: "relative",
        paddingBottom: "calc(56.25% + 41px)",
        height: "0",
        width: "100%",
      }}
    >
      <iframe
        title="TODO: add appropriate title"
        src="https://demo.arcade.software/S0mZYXHXLoHpTx2pDYZq?embed"
        frameborder="0"
        loading="lazy"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          colorScheme: "light",
          title: "Creating a new Project",
        }}
      />
    </div>
  );

  const createSpaceDiv = () => (
    <div
      style={{
        position: "relative",
        paddingBottom: "calc(56.25% + 41px)",
        height: "0",
        width: "100%",
      }}
    >
      <iframe
        title="TODO: add appropriate title"
        src="https://demo.arcade.software/P1QbpOjfoJF2Ax4w0FGW?embed"
        frameborder="0"
        loading="lazy"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          colorScheme: "light",
          title: "Creating a Space",
        }}
      />
    </div>
  );

  const addUserToProjectDiv = () => (
    <div
      style={{
        position: "relative",
        paddingBottom: "calc(56.25% + 41px)",
        height: "0",
        width: "100%",
      }}
    >
      <iframe
        title="TODO: add appropriate title"
        src="https://demo.arcade.software/tpmTFxL7bJ84lvpfLZzv?embed"
        frameborder="0"
        loading="lazy"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          colorScheme: "light",
          title: "Adding Users to the Project",
        }}
      />
    </div>
  );

  const addUserToSpaceDiv = () => (
    <div
      style={{
        position: "relative",
        paddingBottom: "calc(56.25% + 41px)",
        height: "0",
        width: "100%",
      }}
    >
      <iframe
        title="TODO: add appropriate title"
        src="https://demo.arcade.software/xSo57jShO56QjRMzNMCk?embed"
        frameborder="0"
        loading="lazy"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          colorScheme: "light",
          title: "Adding users to Space",
        }}
      />
    </div>
  );

  const folderManagmentDiv = () => (
    <div
      style={{
        position: "relative",
        paddingBottom: "calc(56.25% + 41px)",
        height: "0",
        width: "100%",
      }}
    >
      <iframe
        title="TODO: add appropriate title"
        src="https://demo.arcade.software/ghtP7xgELSuRCuNsTUH6?embed"
        frameborder="0"
        loading="lazy"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          colorScheme: "light",
          title: "Folder Management and Managing files.",
        }}
      />
    </div>
  );

  const fileViewAndUploadDiv = () => (
    <div
      style={{
        position: "relative",
        paddingBottom: "calc(48.50694444444444% + 41px)",
        height: "0",
        width: "100%",
      }}
    >
      <iframe
        title="TODO: add appropriate title"
        src="https://demo.arcade.software/WrQrySWzf7IH4SKjwLE5?embed"
        frameborder="0"
        loading="lazy"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          colorScheme: "light",
          title: "Uploading and viewing a file",
        }}
      />
    </div>
  );

  const header = () => (
    <div className="pb-10 text-3xl font-semibold">Get Started With Cube</div>
  );

  const content = () => (
    <div className="flex w-full h-125 pt-10">
      <div className="flex h-full w-1/3 flex-col overflow-auto">
        <div
          id="createProject"
          onClick={handleSectionClick}
          className={`${commonClass}  ${
            activeSection === "createProject" ? activeClass : ""
          }`}
        >
          Creating a New Project
        </div>
        <div
          id="createSpace"
          onClick={handleSectionClick}
          className={`${commonClass} ${
            activeSection === "createSpace" ? activeClass : ""
          }`}
        >
          Creating a Space
        </div>
        <div
          id="addUserToProject"
          onClick={handleSectionClick}
          className={`${commonClass} ${
            activeSection === "addUserToProject" ? activeClass : ""
          }`}
        >
          Adding Users to the Project
        </div>
        <div
          id="addUserToSpace"
          onClick={handleSectionClick}
          className={`${commonClass} ${
            activeSection === "addUserToSpace" ? activeClass : ""
          }`}
        >
          Adding Users to the Space
        </div>
        <div
          id="folderManagment"
          onClick={handleSectionClick}
          className={`${commonClass} ${
            activeSection === "folderManagment" ? activeClass : ""
          }`}
        >
          Folder Management and Managing Files
        </div>
        <div
          id="fileViewAndUpload"
          onClick={handleSectionClick}
          className={`${commonClass} ${
            activeSection === "fileViewAndUpload" ? activeClass : ""
          }`}
        >
          Uploading and Viewing a File
        </div>
      </div>
      <div className="flex h-full w-2/3 overflow-auto">
        {activeSection === "createProject" && createProjectDiv()}
        {activeSection === "createSpace" && createSpaceDiv()}
        {activeSection === "addUserToProject" && addUserToProjectDiv()}
        {activeSection === "addUserToSpace" && addUserToSpaceDiv()}
        {activeSection === "folderManagment" && folderManagmentDiv()}
        {activeSection === "fileViewAndUpload" && fileViewAndUploadDiv()}
      </div>
    </div>
  );

  return (
    <>
      <div className={css.root}>
        <div className={css.body}>
          <img
            className="w-36 xl:pb-4"
            src={`${process.env.REACT_APP_IMAGES_PUBLIC_URL}/logoAndText.png`}
          />
          <div className={css.imageWrapper}>
            <img
              className="w-96 xl:w-96"
              src={`${process.env.REACT_APP_IMAGES_PUBLIC_URL}/welcome.svg`}
            />
            <div className={css.welcomeText}>
              <span className={css.welcomeHeader}>
                {`Welcome to the ${portalData.name} Portal!`}
              </span>
              <span className={css.welcomeDescription}>
                You are on the PRO plan. Next you can :{" "}
              </span>
            </div>
          </div>
          <div className={css.boxWrapper}>
            <div className={css.box} onClick={handleDashboardRedirect}>
              <img
                className={css.boxImage}
                src={`${process.env.REACT_APP_IMAGES_PUBLIC_URL}/four_square.svg`}
              />
              <span className={css.boxText}>Access your Dashboard</span>
            </div>
            <div className={css.box} onClick={handleShowTutorial}>
              <img
                className={css.boxImage}
                src={`${process.env.REACT_APP_IMAGES_PUBLIC_URL}/media.svg`}
              />
              <span className={css.boxText}>
                View a Tutorial on how to create a project and get started.
              </span>
            </div>
            <div className={css.box} onClick={handleDocumentationRedirect}>
              <img
                className={css.boxImage}
                src={`${process.env.REACT_APP_IMAGES_PUBLIC_URL}/document.svg`}
              />
              <span className={css.boxText}>
                Access our Knowledge Base - A complete Guide to CUBE
              </span>
            </div>
          </div>
          <div className={css.bottomTextContainer}>
            <span>
              As a part of the Pro plan, you can access Document Management, CAD
              & BIM Viewer, create and track issues, send transmittals and more.
            </span>
            <span>
              Want to work with more advanced features like Estimation,
              Scheduling and others? Sign up for our
              <a
                href="https://cube.ms/enterprise"
                target="_blank"
                className={css.enterprisePlanText}
                rel="noreferrer"
              >
                {" "}
                Enterprise plan
              </a>
              .
            </span>
          </div>
        </div>
      </div>
      <ModalStateDialogComponent
        isVisible={showTutorial}
        width="90%"
        headerTitle="Get Started With Cube"
        modelContent={content()}
        onCancel={handleHideTutorial}
      />
      <div />
    </>
  );
}

export default Welcome;
