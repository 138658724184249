import React, { FC, forwardRef, useRef } from "react";

import { createUseStyles } from "react-jss";
import {
  TooltipComponent as SyncfusionTooltipComponent,
  TooltipModel,
} from "@syncfusion/ej2-react-popups";
import { TooltipComponentProps } from "../../interface";
import { getTooltipStyle } from "../../constants/globalFunctions";
import classNames from 'classnames';

const ToolTipComponent: FC<TooltipComponentProps & TooltipModel> = forwardRef((props: TooltipComponentProps & TooltipModel, ref) => {
  /******************* 
@Purpose : Tooltip Component
@Parameter : TooltipComponentProps
@Author : Techture
 *******************/
  const useStyles = createUseStyles({
    tooltip: getTooltipStyle(
      props.bgColor as string,
      props.fontSize as string,
      props.color as string,
      props.tipColor as string
    ),
  });

  const classes = useStyles() as any;
  const tooltipRef = ref || useRef();

  return (
    <SyncfusionTooltipComponent
      {...props}
      ref={tooltipRef}
      content={props.content || ""}
      cssClass= {classNames(`${classes.tooltip}`, `${props.cssClass}`)}
      enableHtmlSanitizer
    >
      {props.children}
    </SyncfusionTooltipComponent>
  );
})
ToolTipComponent.defaultProps = {
  width: "auto",
};
export default ToolTipComponent;