export function refreshFileManager(state = false, action) {
  switch (action.type) {
    case "REFRESH_FILE_MANAGER": {
      return action.data;
    }
    default:
      return state;
  }
}

export function selectedFiles(state = [], action) {
  switch (action.type) {
    case "SET_SELECTED_FILE": {
      return action.data;
    }
    default:
      return state;
  }
}

export function currentFolder(state = null, action) {
  switch (action.type) {
    case "SET_CURRENT_FOLDER": {
      return action.data;
    }
    case "EMPTY_CURRENT_FOLDER": {
      return null;
    }
    default:
      return state;
  }
}

export function fileManagerBreadCrumb(state = [], action) {
  switch (action.type) {
    case "SET_FILE_MANAGER_BREADCRUMB_DATA": {
      return action.data;
    }
    default:
      return state;
  }
}
