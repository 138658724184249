const initialProjectsStatusesState = {};

export function projectsStatuses(state = initialProjectsStatusesState, action) {
  switch (action.type) {
    case "SET_PROJECTS_STATUSES_DATA":
      return action.data.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: curr }),
        {},
      );
    default:
      return state;
  }
}
