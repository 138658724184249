import { useEffect, useState } from "react";
import css from "./index.module.css";
import PaymentPlan from "../PaymentPlan";
import { useSelector } from "react-redux";
import ModalStateDialogComponent from "../../cubeComponent/html/modal/ModalWithState";

export default function PaymentPlanWarning() {
  const portalData = useSelector((state) => state.portalData);
  const userPermissions = useSelector((state) => state.userPermissions);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showPaymentWarning, setShowPaymentWarning] = useState(false);

  useEffect(() => {
    if (portalData.status == "UNVERIFIED") {
      setShowPaymentWarning(true);
    }
  }, [portalData]);

  useEffect(() => {
    if (
      userPermissions.RESOURCE_TYPE_PORTAL?.permissions?.includes(
        "ACCESS_PORTAL_SETTINGS",
      )
    ) {
      setIsAdmin(true);
    }
  }, [userPermissions.RESOURCE_TYPE_PORTAL?.permissions]);

  return showPaymentWarning && isAdmin ? (
    <div className={css.root}>
      <PaymentPlan redirectPortal={portalData.name} portalId={portalData.id} />
    </div>
  ) : null;

  // <ModalStateDialogComponent
  //   isVisible={showPaymentWarning}
  //   // onCancel={handleCloseRateAnalysisModal}
  //   // onSave={handleRateAnalysisSave}
  //   headerTitle="Payment Warning"
  //   width="80%"
  //   disableDefaultFooter={true}
  //   modelContent={isAdmin ? (
  //     <div >
  //       <PaymentPlan redirectPortal={portalData.name} portalId={portalData.id} />
  //     </div>
  //   ) : (
  //     <div>
  //       Please contact your portal administrator for the payment
  //     </div>
  //   )}
  // />
}
