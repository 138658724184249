import css from "./index.module.css";

function LicenceWarning() {
  return (
    <div className={css.root}>
      Licensing error. Please contact your administrator or try accessing the
      application after some time.
    </div>
  );
}

export default LicenceWarning;
