const initialState = {};

export function Databank(state = initialState, action) {
  switch (action.type) {
    case "SET_DATABANK_DATA": {
      return {
        ...state,
        [action.id]: {
          [action.fileVersionId]: {
            ...(state[action.id]?.[action.fileVersionId] || {}),
            ...action.data,
          },
        },
      };
    }

    case "UPDATE_CURRENT_DATABANK_VIEW": {
      const currentSelectedView =
        state[action.id][action.fileVersionId].selectedView;

      return {
        ...state,
        [action.id]: {
          [action.fileVersionId]: {
            ...(state[action.id]?.[action.fileVersionId] || {}),
            views: {
              ...state[action.id][action.fileVersionId].views,
              [currentSelectedView]: {
                ...state[action.id][action.fileVersionId].views[
                  currentSelectedView
                ],
                ...action.data,
              },
            },
          },
        },
      };
    }

    case "RESET_DATABANK": {
      return {
        ...state,
        [action.id]: {
          [action.fileVersionId]: {},
        },
      };
    }

    default:
      return state;
  }
}
