import { issues } from "./issues";

export function HoopsModalStatus(state = [], action) {
  switch (action.type) {
    case "SET_MODAL_STATUS":
      return action.data;
    case "SET_ANIMATION_FOR_ISSUE_CREATION":
      return {
        ...state,
        Issues: {
          ...state.Issues,
          flagForAnimation: action.data,
        },
      };
    default:
      return state;
  }
}

export function isSheetActive(state = false, action) {
  switch (action.type) {
    case "SET_IS_SHEET_ACTIVE":
      return action.data;
    default:
      return state;
  }
}

export function faceSelection(state = false, action) {
  switch (action.type) {
    case "SET_FACE_SELECTION":
      return action.data;
    default:
      return state;
  }
}

export function modelProperties(
  state = {
    data: [],
    isLoading: false,
    isLoaded: false,
    isError: false,
  },
  action,
) {
  switch (action.type) {
    case "SET_MODEL_PROPERTIES":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}

export function GetSettingModalData(state = {}, action) {
  switch (action.type) {
    case "SET_SETTING_MODAL_API_DATA":
      return action.data;

    case "UPDATE_SETTING_MODAL_API_DATA":
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
}

export function nodeIdAndUniqueIdMap(state = {}, action) {
  switch (action.type) {
    case "SET_NODEID_AND_UNIQUEID_MAP": {
      const cubeUniqueIdMap = {};

      for (const i in action.data.data) {
        if (cubeUniqueIdMap.hasOwnProperty(action.data.data[i])) {
          cubeUniqueIdMap[action.data.data[i]].push(Number(i));
        } else {
          cubeUniqueIdMap[action.data.data[i]] = [Number(i)];
        }
      }

      return {
        ...state,
        [action.data.fileVersionId]: {
          nodeIdMap: action.data.data,
          cubeUniqueIdMap,
        },
      };
    }
    default:
      return state;
  }
}

export function isBottomToolbarRefreshRequired(state = false, action) {
  switch (action.type) {
    case "SET_BOTTOM_TOOLBAR_REFRESH":
      return action.data;
    default:
      return state;
  }
}

export function transparentElements(state = [], action) {
  switch (action.type) {
    case "UPDATE_TRANSPARENT_ELEMENTS":
      return action.data;
    default:
      return state;
  }
}

export function viewerState(
  state = {
    isSceneReady: false,
    selectionMode: "element",
  },
  action,
) {
  switch (action.type) {
    case "SET_VIEWER_STATE":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}

export function getSelectedNestedMeasurements(state = {}, action) {
  switch (action.type) {
    case "SET_SELECTED_NESTED_MEASUREMENTS":
      return action.data;
    default:
      return state;
  }
}

export function measurementAttributePopup(
  state = { show: false, callback: null, structure: null },
  action,
) {
  switch (action.type) {
    case "SET_MEASUREMENT_ATTRIBUTE_POPUP":
      return {
        ...action.data,
      };

    default:
      return state;
  }
}
