const initialIssuesStatusesState = "";

export function extraIssue(state = initialIssuesStatusesState, action) {
  switch (action.type) {
    case "SET_EXTRA_ISSUES_DATA":
      return action.data;
    default:
      return state;
  }
}
