import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { getNameInitials } from "../constants/globalFunctions";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../constants/globalVariable";

const StyledAvatar = styled(Avatar)(({ size }) => ({
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  "&.MuiAvatar-root": {
    fontSize: size === "XXS" ? "0.5rem" : size === "XS" ? "0.5rem" : "1rem",
  },
}));

const getSizeClass = (size = "SM") => {
  switch (size) {
    case "XXS":
      return {
        width: "var(--size-20)",
        height: "var(--size-20)",
      };
    case "XS":
      return {
        width: "var(--size-24)",
        height: "var(--size-24)",
      };
    case "LSM":
      return {
        width: "var(--size-30)",
        height: "var(--size-30)",
      };
    case "SM":
      return {
        width: "var(--size-36)",
        height: "var(--size-36)",
      };
    case "LG":
      return {
        width: "var(--size-48)",
        height: "var(--size-48)",
      };
    case "LLG":
      return {
        width: "var(--size-75)",
        height: "var(--size-75)",
      };
    case "XL":
      return {
        width: "var(--size-100)",
        height: "var(--size-100)",
      };
    case "GR":
      return {
        width: "var(--size-40)",
        height: "var(--size-40)",
      };
    default:
      return {
        width: "var(--size-36)",
        height: "var(--size-36)",
      };
  }
};

function AvatarComponent({
  isCircle = false,
  isSquare = false,
  size = "SM",
  bgColor = "var(--system-colour-2)",
  style,
  id,
  renderedContent,
}) {
  const [error, setError] = useState(false);
  const variant = isSquare ? "square" : isCircle ? "circular" : "circular";

  const { userTagData } = useSelector((state) => state);
  let avatarProps;
  let profilePicKey;

  if (id) {
    profilePicKey = userTagData[id].data.profilePic;
    avatarProps =
      profilePicKey && !error
        ? {
            src: `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getImage/${CONSTANTS.PROFILE}/${id}`,
            onError: () => setError(true),
          }
        : {};
  }

  const avatarStyle = {
    backgroundColor: bgColor,
    ...getSizeClass(size),
    ...style,
    border: "0",
  };

  return (
    <>
      {userTagData[id] && userTagData[id].data && userTagData[id].isLoaded && (
        <StyledAvatar
          variant={variant}
          style={avatarStyle}
          size={size}
          {...avatarProps}
        >
          {(!profilePicKey || error) &&
            getNameInitials({
              firstName: userTagData[id].data.firstName,
              lastName: userTagData[id].data.lastName,
            })}
        </StyledAvatar>
      )}
      {renderedContent && (
        <StyledAvatar style={avatarStyle} size={size}>
          <Avatar size={size} varaint={variant} style={avatarStyle}>
            {renderedContent}
          </Avatar>
        </StyledAvatar>
      )}
    </>
  );
}

export default AvatarComponent;
