import axiosWrapper from "../services/axiosWrapper";

export function readNotificationsData(idsArr) {
  return {
    type: "READ_NOTIFICATION_DATA",
    data: idsArr,
  };
}

export function unreadNotificationsData(idsArr) {
  return {
    type: "UNREAD_NOTIFICATION_DATA",
    data: idsArr,
  };
}

export function setNotificationsData(data) {
  return {
    type: "SET_NOTIFICATION_DATA",
    data,
  };
}

export function setUnreadNotificationsCount(data) {
  return {
    type: "SET_UNREAD_NOTIFICATION_COUNT",
    data,
  };
}

export function setShowNotification(data) {
  return {
    type: "SET_SHOW_NOTIFICATION",
    data,
  };
}

export async function getNotifications(
  newCountRequired = 4,
  oldCountRequired = 4,
) {
  const data = {
    value: {
      source: "header",
      newNotificationsFilter: {
        notificationType: ["NOTIFICATION_UNREAD"],
        requiresCounts: false,
        sortBy: { createdDate: -1 },
        limit: newCountRequired,
        offset: 0,
      },
      previousNotificationsFilter: {
        notificationType: ["NOTIFICATION_READ"],
        requiresCounts: false,
        sortBy: { createdDate: -1 },
        limit: oldCountRequired,
        offset: 0,
      },
    },
  };

  try {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/notifications/getNotifications`,
      data,
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export function getNotificationsCount(filterType) {
  const data = {
    filter: filterType,
  };
  return async (dispatch) => {
    try {
      const response = await axiosWrapper.post(
        `${process.env.REACT_APP_CUBE_BACKEND}/notifications/getNotificationsCount`,
        data,
      );

      if (response.data.status) {
        dispatch(setUnreadNotificationsCount(response.data.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export async function readNotification(idsArr) {
  const data = {
    ids: idsArr,
  };

  try {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/notifications/dismissNotification`,
      data,
    );

    return response.data.status;
  } catch (err) {
    console.log(err);
  }
}

export async function unreadNotification(idsArr) {
  const data = {
    ids: idsArr,
  };

  try {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/notifications/unreadNotification`,
      data,
    );

    return response.data.status;
  } catch (err) {
    console.log(err);
  }
}
