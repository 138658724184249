export function contractDetails(state = [], action) {
  switch (action.type) {
    case "SET_CONTRACT_DETAILS":
      return {
        ...action.data,
      };

    default:
      return state;
  }
}
