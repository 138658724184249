import React, { FC, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { AvatarComponentProps } from "../../interface";
import {
  defaultAvatarComponentProps,
} from "../../constants/globalVariable";
import { getAvatarStyle, getSizeClass, getNameInitials } from "../../constants/globalFunctions";

const AvatarComponent: FC<AvatarComponentProps> = (props: AvatarComponentProps) => {
  /******************* 
  @Purpose : Avatar Component
  @Parameter : AvatarComponentProps
  @Author : Techture
 *******************/

  const useStyles = createUseStyles({
    avatar: getAvatarStyle(props.bgColor as string),
  });

  const classes = useStyles() as any;
  const [error, setError] = useState(false); // State to track API call error
  const [nameInitials, setNameInitials] = useState('')

  useEffect(() => {
    if(props.userName) {
      const userNameArray = props.userName.split(' ');
      const userInitials = getNameInitials({
        firstName: userNameArray[0] || '',
        lastName: userNameArray[1] || '',
      });
      setNameInitials(userInitials)
    } else if(props.userDetails) {
      const userInitials = getNameInitials(props.userDetails);
      setNameInitials(userInitials)
    }
  }, [props.userName, props.userDetails])

  return (
    <div
      className={`e-avatar ${getSizeClass(props.size as string)} ${props.isCircle
          ? "e-avatar-circle"
          : props.isSquare
            ? "e-avatar-square-organization"
            : "e-avatar-square-portal"
        } ${classes.avatar}`}
      style={props.style}
    > 
      {props.children ? props.children : <>
        {props.profilePicKey && !error ?
          <img src={`${process.env.REACT_APP_CUBE_BACKEND}/${props.profilePicKey}`} onError={() => setError(true)}/> :
          nameInitials
        }
        </>}
    </div>
  );
};
AvatarComponent.defaultProps = defaultAvatarComponentProps;
export default AvatarComponent;
