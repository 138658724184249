import React, { FC } from "react";
import { IconComponentProps } from "../interface";
import Tool from "../../components/HoopsViewer/ToolbarViewer/Tool";

const IconComponent: FC<IconComponentProps> = (props: IconComponentProps) => {
  const getDefaultImage = () => (
      <img
        src={require("../../assets/icons/svg/default.svg")}
        alt={`${props.name}`}
        height={props.height}
        onClick={props.onClick}
        className={`${props.cssClass} ${props.className}`}
      />
    );

  const renderIcon = () => {
    if (props.name) {
      try {
        const src = require(`../../assets/icons/svg/${props.name}.svg`)
        return (
          <img
            src={src}
            alt={`${props.name}`}
            height={props.height}
            onClick={props.onClick}
            className={`${props.cssClass} ${props.className}`}
          />
          // <Tool 
          //   Svg={require(`../../assets/icons/svg/${props.name}.svg`)} 
          //   Tooltip = {props.tooltip}
          //   onClick={props.onClick}
          //   color= 'rgb(107, 187, 227)'
          // >
          // </Tool>
        )
        
      } catch (err) {
        return getDefaultImage();
      }
    } else {
      return getDefaultImage();
    }
  };

  return renderIcon();
};

export default IconComponent;
