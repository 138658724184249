const getViewerUrl = ({
  fileVersionId, transmittalVersionId, issueId, estimateId, measurementId, versionId, spaceId, scheduleId, projectId, contractId, preloadAndOpen, shortcutParentId, clashDetectionId
}) => {
  if (!projectId) {
    return
  }
  const location = `/designviewer/file/${fileVersionId}`;
  const searchParams = new URLSearchParams('');

  if (scheduleId) {
    searchParams.set('scheduleId', scheduleId);
  }
  if (projectId) {
    searchParams.set('projectId', projectId);
  }
  if (spaceId) {
    searchParams.set('spaceId', spaceId);
  }
  if (contractId) {
    searchParams.set('contractId', contractId);
  }
  if (projectId) {
    searchParams.set('projectId', projectId);
  }
  if (transmittalVersionId) {
    searchParams.set('transmittalId', transmittalVersionId);
  }

  if (shortcutParentId) {
    searchParams.set('shortcutParentId', shortcutParentId);
  }
  if (issueId) {
    searchParams.set('issueId', issueId);
  }
  if (preloadAndOpen) {
    searchParams.set('mode', 'scs');
  }
  if (estimateId) {
    searchParams.set('estimateId', estimateId);
    if (measurementId) {
      searchParams.set('measurementId', measurementId);
    }
    if (versionId) {
      searchParams.set('versionId', versionId);
    }
  }
  if (clashDetectionId) {
    searchParams.set('clashDetectionId', clashDetectionId);
  }

  return `${location}?${searchParams.toString()}`;
};

const getInspectionUrl = ({
  projectId, resourceId,selectedTab,resourceType,contractId
}) => {
  const url = `/project/${projectId}/forms/${resourceId}`
  const searchParams = new URLSearchParams('');

  searchParams.set('projectId', projectId);
  if(contractId) {
    searchParams.set('contractId', contractId);
  } 
  if(resourceId){
    searchParams.set('resourceId', resourceId);
  }
  return `${url}?${searchParams.toString()}`;
};

module.exports = { getViewerUrl, getInspectionUrl };
