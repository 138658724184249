export const estimateFieldsNeedToRefreshIntialState = {
  takeoffs: [],
  measurements: false,
};
export function getEstimateList(state = [], action) {
  switch (action.type) {
    case "GET_ESTIMATE_LIST":
      return action.data;
    case "EMPTY_ESTIMATE_LIST":
      return [];
    default:
      return state;
  }
}

export function getEstimateAdditionalCost(state = [], action) {
  switch (action.type) {
    case "SET_ADDITIONAL_COST":
      return action.data;

    default:
      return state;
  }
}

export function additionalCostTotal(state = 0, action) {
  const { type, data } = action;

  if (type === "SET_ESTIMATE_ADDITIONAL_COST_TOTAL") {
    return data;
  }

  return state;
}

export function getDateVersion(state = [], action) {
  switch (action.type) {
    case "GET_ESTIMATE_DATEVERSION":
      return action.data;
    default:
      return state;
  }
}

export function estimateData(state = {}, action) {
  const { type, data } = action;

  if (type === "ESTIMATE_DATA") {
    return data;
  }

  return state;
}

export function estimateFieldsNeedToRefresh(
  state = estimateFieldsNeedToRefreshIntialState,
  action,
) {
  const { type, data } = action;

  if (type === "SET_ESTIMATE_FIELDS_NEEDTO_REFRESH") {
    return data;
  }

  return state;
}
