import { getUrlForImage } from "../cubeComponent/constants/globalFunctions";
import { CONSTANTS } from "../cubeComponent/constants/globalVariable";
import axiosWrapper from "../services/axiosWrapper";

export function saveUserDetails(data) {
  return {
    type: "SET_USER_DETAILS",
    data,
  };
}

export function updateUserDetails(data) {
  return {
    type: "UPDATE_USER_DETAILS",
    data,
  };
}

export function getUserDetails() {
  return (dispatch) => {
    axiosWrapper
      .get(`${process.env.REACT_APP_CUBE_BACKEND}/accounts/getUserDetails`)
      .then((response) => {
        if (response && response.data.status) {
          const res = response.data.data[0];
          dispatch(
            saveUserDetails({
              ...res,
              lastName: res.lastName ? res.lastName : "",
              profilePicUrl: res.profilePic ? getUrlForImage(res) : "",
              company: {
                ...res.company,
                logoUrl: res.company?.logo
                  ? getUrlForImage(res.company, CONSTANTS.ORGANIZATION)
                  : "",
              },
              // portal: {
              //   ...res.portal,
              //   logoUrl: res?.portal?.logo ? getUrlForImage(res.portal, CONSTANTS.PORTAL) : '',
              // },
              signatureUrl: res.signature
                ? `${
                    process.env.REACT_APP_CUBE_BACKEND
                  }/fileSystemServer/getSignDocs/${res.signature}?${Date.now()}`
                : "",
              status: true,
            }),
          );
        } else {
          dispatch(
            saveUserDetails({
              status: false,
            }),
          );
        }
      });
  };
}

export async function initiateSignupEmailVerification(email) {
  return await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/accounts/initiateSignupEmailVerification`,
    {
      email,
    },
  );
}
export async function getUserDetailsById(data) {
  return await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/accounts/getUserDetailsById`,
    data,
  );
}
