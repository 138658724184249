export const SignTypeValues = {
  TYPE_NAME_INITIAL: "TYPE_NAME_INITIAL",
  DRAW_ON_SCREEN: "DRAW_ON_SCREEN",
  IMPORT_AS_IMAGE: "IMPORT_AS_IMAGE",
  EMBED_INSIDE_FILE: "EMBED_INSIDE_FILE",
  ATTACH_SIGNED_DOCUMENT: "ATTACH_SIGNED_DOCUMENT",
  IMPORT_PROFILE_SIGNATURE: "IMPORT_PROFILE_SIGNATURE",
};

export const SignTypes = [
  // {
  //   name: "Type Name or Initials",
  //   value: SignTypeValues.TYPE_NAME_INITIAL
  // },
  {
    name: "Draw On Screen",
    value: SignTypeValues.DRAW_ON_SCREEN,
  },
  // {
  //   name: "Import as image",
  //   value: SignTypeValues.IMPORT_AS_IMAGE
  // }, {
  //   name: "Embed inside file",
  //   value: SignTypeValues.EMBED_INSIDE_FILE
  // },
  {
    name: "Attach Signed Document",
    value: SignTypeValues.ATTACH_SIGNED_DOCUMENT,
  },
  {
    name: "Import Profile Signature",
    value: SignTypeValues.IMPORT_PROFILE_SIGNATURE,
  },
];
