import { DateTime } from "luxon";

function convertDate(date, dateFormat) {
  const time = DateTime.fromMillis(Number(date));
  let format = `${time.toFormat(dateFormat)}`;

  if (dateFormat === "dateForText") {
    format = `${time.toFormat("MMM dd")} at ${time.toFormat("hh:mm a")}`;
  } else if (dateFormat === "dateForTooltip") {
    format = `${time.toFormat("MMM dd,yyyy")} at ${time.toFormat(
      "hh:mm:ss a",
    )}`;
  } else if (
    dateFormat === "dateForProfile" ||
    dateFormat === "dateForOrganization"
  ) {
    format = `${time.toFormat("MMM dd, yyyy")}`;
  } else if (dateFormat === "NormalDate") {
    format = `${time.toFormat("dd/MM/yyyy")}`;
  } else if (dateFormat === "NormalDateTime") {
    format = `${time.toFormat("MM/dd/yyyy hh:mm a")}`;
  } else if (dateFormat === "getDifferenceTime") {
    format = time.toRelative();
  } else if (dateFormat === "dateForToolTipModal") {
    format = `${time.toFormat("MMM dd, yyyy")}`;
  } else if (dateFormat === "dateForTooltipSpaceBeforeYear") {
    format = `${time.toFormat("MMM dd, yyyy")} at ${time.toFormat(
      "hh:mm:ss a",
    )}`;
  } else if (dateFormat === "dateForSignHistory") {
    format = time.toFormat("MMM dd hh:mm a");
  } else if (dateFormat === "standardIsoFormat") {
    format = `${time.toISO()}`;
  } else if (dateFormat === "dateForPdfExport") {
    format = `${time.toFormat("MMM dd, yyyy")} at ${time.toFormat("hh:mm a")}`;
  } else if (dateFormat === "dateForPortalDashboard") {
    format = {
      date: time.toFormat("dd MMM yy"),
      day: time.toFormat("cccc"),
      time: time.toFormat("hh:mm:ss a"),
      timeZone: time.toFormat("ZZ"),
    };
  } else if (dateFormat === "dateforProjectDashbaord") {
    format = `${time.toFormat("dd MMM, yy")} ${time.toFormat("hh:mm a")}`;
  } else if (dateFormat === "chatBot") {
    format = `At ${time.toFormat("hh:mm a")}`;
  }

  return format;
}

export { convertDate };
