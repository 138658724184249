export function clashDetails(state = {}, action) {
  switch (action.type) {
    case "SET_CLASH_DETAILS":
      return {
        ...action.data,
      };

    default:
      return state;
  }
}

export function colliderData(state = {}, action) {
  switch (action.type) {
    case "SET_COLLIDER_DATA":
      return {
        ...action.data,
      };

    default:
      return state;
  }
}
export function collideeData(state = {}, action) {
  switch (action.type) {
    case "SET_COLLIDEE_DATA":
      return {
        ...action.data,
      };

    default:
      return state;
  }
}
