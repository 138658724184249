import { useDispatch } from "react-redux";
import axiosWrapper from "@Services/axiosWrapper";
import { useNavigate } from "react-router-dom";
import { saveUserDetails } from "../../actions/userDetails";

const useResetAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetAuth = async () => {
    localStorage.setItem("isLogedIn", false);

    const ssoStrategy = await axiosWrapper.get(
      `${process.env.REACT_APP_CUBE_BACKEND}/accounts/getSsoStrategy`,
    );
    const responseData = await axiosWrapper.get(
      `${process.env.REACT_APP_CUBE_BACKEND}/accounts/getLogoutData`,
    );

    if (ssoStrategy.data.status && ssoStrategy.data.strategy != "local") {
      // This condition is used for SAML logout in case of CPWD
      window.location.href = responseData.data.data.redirectUrl;
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      let redirectUrl = encodeURIComponent(
        `${location.pathname}?${urlParams.toString()}`,
      );

      if (urlParams.has("redirectUrl")) {
        redirectUrl = encodeURIComponent(urlParams.get("redirectUrl"));
      }
      navigate(`/?redirectUrl=${redirectUrl}${window.location.hash}`);
    }
    dispatch(saveUserDetails({}));
  };

  return resetAuth;
};

export default useResetAuth;
